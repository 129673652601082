import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import {
  AutocompletePackageQueryVm,
  CreatePackageModel,
  DropdownModel,
  FilterMasterItemQueryVm,
  GetItemInPackageByPackageCodeQueryVm,
  GetListItemMasterVm,
  GetMasterItemQueryVm,
  GetPackagePriceQueryVm,
  GetPrivilegeDataInitQueryVm,
  GetRoleMasterByIdQueryVm,
  GetSearchRoleListQueryVm,
  GetSelectListPackageVm,
  IGetListPackage,
  IGetPackageById,
  IMasterModel,
  MasterPackage,
  PackageItem,
  PrivilegeAction,
  SearchMasterItemQueryVm,
  SearchMasterPackageQueryVm,
  UpdatePackageModel,
  menuData,
} from '../models/masterModels';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { creatInvoiceModel } from '../models/ticketModels';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  baseUrl = `${environment.apiUrl}${environment.masterApi}`;
  constructor(private http: HttpClient, private utilService: UtilService) {}

  GetMenuPrivilegeList(userName: string, roleCode: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<menuData[]>(
      `${this.baseUrl}/GetMenuPrivilegeList`,
      {
        userName,
        roleCode,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  GetSearchRoleList(page: number, pageSize: number) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<GetSearchRoleListQueryVm>(
      `${this.baseUrl}/GetSearchRoleList`,
      {
        page,
        pageSize,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  GetPrivilegeDataInit() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<GetPrivilegeDataInitQueryVm[]>(
      `${this.baseUrl}/GetPrivilegeDataInit`,
      {},
      {
        headers: httpHeaders,
      }
    );
  }

  GetRoleMasterById(id: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<GetRoleMasterByIdQueryVm>(
      `${this.baseUrl}/GetRoleMasterById`,
      {
        id,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  CreateRole(data: any) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(`${this.baseUrl}/CreateRole`, data, {
      headers: httpHeaders,
    });
  }

  UpdateRole(data: any) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(`${this.baseUrl}/UpdateRole`, data, {
      headers: httpHeaders,
    });
  }

  CheckPrivilegeRole(roleCode: string, menuCode: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<PrivilegeAction>(
      `${this.baseUrl}/CheckPrivilegeRole`,
      {
        roleCode,
        menuCode,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  GetRolesActive(): Observable<DropdownModel[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<DropdownModel[]>(
      `${this.baseUrl}/GetRolesActive`,

      {
        headers: httpHeaders,
      }
    );
  }

  GetRolesAdmin(): Observable<DropdownModel[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<DropdownModel[]>(
      `${this.baseUrl}/GetRolesAdmin`,

      {
        headers: httpHeaders,
      }
    );
  }

  GetSolarPackage(): Observable<DropdownModel[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<DropdownModel[]>(
      `${this.baseUrl}/GetSolarPackage`,

      {
        headers: httpHeaders,
      }
    );
  }

  GetPurposeInstall(): Observable<DropdownModel[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<DropdownModel[]>(
      `${this.baseUrl}/GetPurposeInstall`,

      {
        headers: httpHeaders,
      }
    );
  }

  GetSolarProjectType(): Observable<DropdownModel[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<DropdownModel[]>(
      `${this.baseUrl}/GetSolarProjectType`,

      {
        headers: httpHeaders,
      }
    );
  }

  UploadImage() {
    return decodeURI(`${this.baseUrl}/UploadImage`);
  }

  UploadAvatar() {
    return decodeURI(`${this.baseUrl}/UploadFiles/AVA`);
  }

  UploadSignAdmin() {
    return decodeURI(`${this.baseUrl}/UploadFiles/SIA`);
  }

  UploadPayment() {
    return decodeURI(`${this.baseUrl}/UploadFiles/PY`);
  }

  GetListMasterByMajor(majorCode: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<IMasterModel[]>(
      `${this.baseUrl}/GetListMasterByMajor`,
      {
        majorCode,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  GetSelectListPackage(solution: string) {
    const url = `${this.baseUrl}/GetSelectListPackage`;
    return this.http.post<GetSelectListPackageVm[]>(url, {
      solution,
    });
  }

  GetListItemMaster(packageCode: string) {
    const url = `${this.baseUrl}/GetListItemMaster`;
    return this.http.post<GetListItemMasterVm[]>(url, {
      packageCode,
    });
  }

  GetPackagePrice(packageCode: string) {
    const url = `${this.baseUrl}/GetPackagePrice`;
    return this.http.post<GetPackagePriceQueryVm>(url, {
      packageCode,
    });
  }

  GetListPackage(
    page: number,
    pageSize: number,
    packageCode: string,
    packageName: string,
    solution: string,
    solarType: string
  ) {
    const url = `${this.baseUrl}/GetListPackage`;
    return this.http.post<IGetListPackage>(url, {
      page,
      pageSize,
      packageCode,
      packageName,
      solution,
      solarType,
    });
  }

  CreatePackage(creatPackageItem: CreatePackageModel) {
    const url = `${this.baseUrl}/CreatePackage`;
    return this.http.post(url, creatPackageItem);
  }

  DeletePackage(id: string, updateBy: string) {
    const url = `${this.baseUrl}/DeletePackage`;
    return this.http.post(url, {
      id,
      updateBy,
    });
  }

  UpdatePackage(UpdatePackage: UpdatePackageModel) {
    const url = `${this.baseUrl}/UpdatePackage`;
    return this.http.post(url, UpdatePackage);
  }

  GetPackageById(id: string) {
    const url = `${this.baseUrl}/GetPackageById`;
    return this.http.post<IGetPackageById>(url, { id });
  }

  SearchMasterItem(
    page: number,
    pageSize: number,
    itemName: string,
    itemType: string,
    brand: string,
    forSolution: string
  ) {
    const url = `${this.baseUrl}/SearchMasterItem`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<SearchMasterItemQueryVm>(
      url,
      {
        page,
        pageSize,
        itemName,
        itemType,
        brand,
        forSolution,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  CreateMasterItem(
    itemName: string,
    itemType: string,
    brand: string,
    unit: string,
    description: string,
    forSolution: string,
    netPrice: number,
    sellPrice: number,
    createBy: string
  ) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(
      `${this.baseUrl}/CreateMasterItem`,
      {
        itemName,
        itemType,
        brand,
        unit,
        description,
        forSolution,
        netPrice,
        sellPrice,
        createBy,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  UpdateMasterItem(
    itemId: string,
    itemName: string,
    itemType: string,
    brand: string,
    unit: string,
    description: string,
    forSolution: string,
    netPrice: number,
    sellPrice: number,
    updateBy: string
  ) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(
      `${this.baseUrl}/UpdateMasterItem`,
      {
        itemId,
        itemName,
        itemType,
        brand,
        unit,
        description,
        forSolution,
        netPrice,
        sellPrice,
        updateBy,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  GetMasterItem(id: string): Observable<GetMasterItemQueryVm> {
    const url = `${this.baseUrl}/GetMasterItem/${id}`;
    return this.http.get<GetMasterItemQueryVm>(url);
  }

  SearchMasterPackage(
    packageName: string,
    forSolution: string,
    solarType: string,
    page: number,
    pageSize: number
  ) {
    const url = `${this.baseUrl}/SearchMasterPackage`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<SearchMasterPackageQueryVm>(
      url,
      {
        page,
        pageSize,
        packageName,
        solarType,
        forSolution,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  FilterMasterItem(filter: string) {
    const url = `${this.baseUrl}/FilterMasterItem`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<FilterMasterItemQueryVm[]>(
      url,
      {
        filter,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  CreateMasterPackage(
    packageName: string,
    forSolution: string,
    solarType: string,
    forPaymentType: string[],
    customerType: string[],
    summaryPrice: number,
    remark: string,
    packageItems: PackageItem[],
    createBy: string
  ) {
    const url = `${this.baseUrl}/CreateMasterPackage`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(
      url,
      {
        forPaymentType,
        customerType,
        packageName,
        solarType,
        forSolution,
        summaryPrice,
        remark,
        packageItems,
        createBy,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  UpdateMasterPackage(
    packageId: string,
    packageName: string,
    forSolution: string,
    solarType: string,
    forPaymentType: string[],
    customerType: string[],
    summaryPrice: number,
    remark: string,
    packageItems: PackageItem[],
    createBy: string
  ) {
    const url = `${this.baseUrl}/UpdateMasterPackage`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(
      url,
      {
        id: packageId,
        forPaymentType,
        customerType,
        packageName,
        solarType,
        forSolution,
        summaryPrice,
        remark,
        packageItems,
        updateBy: createBy,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  GetMasterPackage(id: string): Observable<MasterPackage> {
    const url = `${this.baseUrl}/GetMasterPackage/${id}`;
    return this.http.get<MasterPackage>(url);
  }


  AutocompletePackage(packageName: string) {
    const url = `${this.baseUrl}/AutocompletePackage`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<AutocompletePackageQueryVm[]>(
      url,
      {
        packageName,
      },
      {
        headers: httpHeaders,
      }
    );
  }

  GetItemInPackageByPackageCode(packageCode: string) {
    const url = `${this.baseUrl}/GetItemInPackageByPackageCode`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<GetItemInPackageByPackageCodeQueryVm[]>(
      url,
      {
        packageCode,
      },
      {
        headers: httpHeaders,
      }
    );
  }
}
